import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ['Community', 'Seed Sale', 'Liquidity', 'Public Sale', 'Team'],
  datasets: [
    {
      label: '# of Votes',
      data: [20, 5, 25, 45, 5],
      backgroundColor: [
        '#AFC5FF',
        '#FDEBB3',
        '#ACF2C4',
        '#CDBDF3',
        '#E89F8E',
        '#6574EB',
      ],
      borderColor: [
        '#AFC5FF',
        '#FDEBB3',
        '#ACF2C4',
        '#CDBDF3',
        '#E89F8E',
        '#6574EB',
      ],
      borderWidth: 1,
    },
  ],
};

export function Chart() {
  return <Doughnut data={data} />;
}
