import img1 from '../images/common/team_1.png';
import img2 from '../images/common/team_2.png';
import img3 from '../images/common/team_3.png';
import img4 from '../images/common/team_4.png';
import img5 from '../images/common/team_5.png';
import img6 from '../images/common/team_6.png';
import img7 from '../images/common/team_7.png';
import img8 from '../images/common/team_8.png';


const dataTeam = [
    {
        id: 1,
        img : img1,
        name: 'Robert Anderson',
        unit: 'CEO'
    },
    {
        id: 2,
        img : img2,
        name: 'Matthew Walker',
        unit: 'CFO'
    },
    {
        id: 3,
        img : img3,
        name: 'Michael Turner',
        unit: 'CTO'
    },
    {
        id: 4,
        img : img4,
        name: 'Nicholas White',
        unit: 'Lead Blockchain Developer'
    },
    {
        id: 5,
        img : img5,
        name: 'Michael Brown ',
        unit: 'Chief Marketing Officer'
    },
    {
        id: 6,
        img : img6,
        name: 'Joseph Carter',
        unit: 'Business Development Director'
    },
    {
        id: 7,
        img : img7,
        name: 'Sophia Mitchell',
        unit: 'Community Manager'
    },
    {
        id: 8,
        img : img8,
        name: 'Emma Williams',
        unit: 'Head of Design'
    },
]

export default dataTeam;
