

const dataFaq = [
    {
        id: 1,
        title: 'What is Launchify?',
        content: 'Launchify is a comprehensive blockchain platform designed to empower and accelerate the launch of innovative projects within the blockchain ecosystem.'
    },
    {
        id: 2,
        title: 'What services does Launchify offer to blockchain projects?',
        content: 'Launchify offers a range of services, including access to funding, mentorship, strategic guidance, community support, and a launchpad for project initiatives.'
    },
    {
        id: 3,
        title: 'How does Launchify support project funding?',
        content: 'Launchify provides a Funding Pool that supports projects by allocating resources from its token supply to promising initiatives. This ensures that projects have the financial backing they need to succeed.'
    },
    {
        id: 4,
        title: ' What role does Launchify\'s community play in the platform?',
        content: 'Launchify\'s community is a dynamic and engaged ecosystem of blockchain enthusiasts, innovators, investors, and supporters. The community provides valuable feedback, collaboration opportunities, and a vibrant network for projects to thrive.'
    },
    {
        id: 5,
        title: 'How does Launchify ensure fair and transparent launch?',
        content: 'Launchify follows fair launch principles, offers that projects are introduced to the community without market manipulation or unfair advantage. This fosters a transparent and ethical launch environment.'
    },
    {
        id: 6,
        title: 'What is the significance of Launchify\'s dual approach to exchange listings?',
        content: ': Launchify exchanges strategically lists its native token, LIF, on both decentralized exchanges (DEX) and centralized exchanges (CEX). This approach ensures accessibility, liquidity, and recognition in both the decentralized and global cryptocurrency markets.'
    },


]

export default dataFaq;
