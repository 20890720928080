import Web3 from 'web3';

class Web3Service {
    constructor() {
        this.web3 = null;
        this.account = null;
    }

    async initWeb3() {
        if (window.ethereum) {
            this.web3 = new Web3(window.ethereum);
            const accounts = await window.ethereum.request({ method: 'eth_accounts' });
            this.account = accounts[0];
            const currentNetwork = await this.web3.eth.net.getId();
            if (currentNetwork.toString() !== '56') {
                window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x38' }], // BSC Mainnet chain ID
                });
                // User is not connected to BSC Mainnet, prompt them to switch
                alert('Please switch to the BSC Mainnet.');
            }

        } else {
            console.warn('MetaMask or compatible wallet not detected.');
        }
    }

    getWeb3() {
        return this.web3;
    }

    getAccount() {
        return this.account;
    }
}

const service = new Web3Service()

export default service;