
const dataRoadmap = [
    {
        id: 1,
        time: 'Phase 1',
        text1: 'Define project goals, objectives, and scope.',
        text2: 'Create a project team and assign roles and responsibilities.',
        text3: 'Conduct initial market research to validate the project idea.',
        text4: 'Identify key features and functionalities.',
        text5: 'Begin drafting initial design sketches or prototypes.',
        status: 'active'
    },
    {
        id: 2,
        time: 'Phase 2',
        text1: 'Refine the project concept and design based on feedback.',
        text2: 'Create detailed technical specifications.',
        text3: 'Develop mockups for user interfaces.',
        text4: 'Start building a functional prototype of the project.',
        text5: 'Develop a marketing and promotional plan.',
        status: 'active'
    },
    {
        id: 3,
        time: 'Phase 3',
        text1: 'Conduct thorough testing across different scenarios.',
        text2: 'Perform bug fixing and error resolution.',
        text3: 'Ensure the project meets quality standards.',
        text4: 'Prepare documentation, user guides, and support materials.',
        text5: 'Create a marketing strategy that outlines target audiences, messaging, and promotional channels.',
        text6: 'Launch social media profiles and a dedicated website to establish an online presence.',
        status: 'active'
    },
    {
        id: 4,
        time: 'Phase 4',
        text1: 'Finalize the details of the seed sale, including token price, allocation, and vesting schedules.',
        text2: 'Ensure compliance with legal requirements and regulations for the sale.',
        text3: 'Secure listings on prominent cryptocurrency exchanges for liquidity and accessibility.',
        text4: 'Coordinate exchange listings to coincide with the public sale phase.',
        text5: 'Set up customer support channels.',
        status: 'active'
    },
    {
        id: 5,
        time: 'Phase 5',
        text1: 'Establish a dedicated section on the launchpad\'s platform for project submissions.',
        text2: 'Develop guidelines and criteria for evaluating and selecting new projects.',
        text3: 'Reach out to promising projects in the blockchain space and encourage them to submit their proposals.',
        text4: 'Facilitate the onboarding process for selected projects, assisting them through the launchpad\'s token sale stages.',
        text5: 'Encourage the existing launchpad community to participate in the selection process by voting or providing feedback on potential projects.',
        status: ''
    },
    {
        id: 6,
        time: 'Phase 6',
        text1: 'Coordinate the launch and promotion of new projects on the launchpad.',
        text2: 'Provide support to the newly onboarded projects to ensure their successful token sale events.',
        status: ''
    },
    {
        id: 7,
        time: 'Phase 7',
        text1: 'Explore partnerships and collaborations to enhance the project.',
        text2: 'Continuously analyze market trends and user preferences for long-term success.',
        text3: 'Implement solutions to enhance the platform\'s scalability, ensuring it can handle increased user and project activity.',
        text4: 'Implement solutions to enhance the platform\'s scalability, ensuring it can handle increased user and project activity.',
        text5: 'Implement solutions to enhance the platform\'s scalability, ensuring it can handle increased user and project activity.',
        status: ''
    },
]

export default dataRoadmap;
