import React , { useState , useEffect } from 'react';

import {Link, NavLink, useSearchParams} from 'react-router-dom';
import menus from '../../pages/menu';
import Button from '../button/index';
import './styles.scss';
import logo from '../../assets/images/logo/logo.png'
import Web3 from 'web3';
import ReactLoading from "react-loading";
import {shortenAddress} from "../../utils/untils";

const Header = () => {
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [scroll, setScroll] = useState(false);
        useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        }
    }, []);
    const [web3, setWeb3] = useState(null);
    const [account, setAccount] = useState(null);
    const [menuActive, setMenuActive] = useState(null);
    useEffect(() => {
        const storedAccount = localStorage.getItem('ethereumAccount');
        if (storedAccount) {
            setAccount(storedAccount);
        }
    }, []);
    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', (accounts) => {
                // Handle the account change in your application
                const newAccount = accounts[0];
                if (newAccount) {
                    setAccount(newAccount);
                    localStorage.setItem('ethereumAccount', newAccount);
                }
                window.location.reload();
            });
        }
    });
    const connectToWeb3 = async () => {
        if (typeof window.ethereum !== 'undefined') {
            try {
                const ethereum = window.ethereum;
                const web3Instance = new Web3(ethereum);

                // Request account access
                await ethereum.request({ method: 'eth_requestAccounts' })

                // Get the user's Ethereum account address
                const accounts = await web3Instance.eth.getAccounts()
                await localStorage.setItem('ethereumAccount', accounts[0])
                await setAccount(accounts[0])

                // Store the web3 instance
                await setWeb3(web3Instance)
                window.location.reload();
            } catch (error) {
                alert(error)
                console.error('Web3 login error:', error);
            }
        } else {
            console.warn('Web3 not available');
        }
    };
    const handleLogout = async () => {
        // Clear the stored Ethereum account
        localStorage.removeItem('ethereumAccount')
        await setAccount(null)
        // window.location.reload();
    };
    const handleMenuActive = () => {
        setMenuActive(!menuActive);
      };


    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index);
    };

    return (
        <div>{loading? (
                <ReactLoading type={'spin'} className={'spin-overlay'} width={'100%'} height={'100%'}></ReactLoading>
            ): (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
             <div className="container">
            <div id="site-header-inner">
                <div className="header__logo">
                    <NavLink to="/"><img src={logo} alt="Launchify" /></NavLink>
                </div>
                <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`} >
                    <ul id="menu-primary-menu" className="menu">

                        {
                            menus.map((data,idx) => (
                                <li key={idx} onClick={()=> handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`}

                                >
                                    <Link to={data.links}>{data.name}</Link>
                                    {
                                        data.namesub &&
                                        <ul className="sub-menu">
                                            {
                                                data.namesub.map((submenu) => (
                                                    <li key={submenu.id} className="menu-item"><NavLink to={submenu.links} onClick={handleDropdown}>{submenu.sub}</NavLink></li>
                                                ))
                                            }
                                        </ul>
                                    }

                                </li>
                            ))
                        }
                    </ul>
                </nav>
                {account ? (
                    <>
                    <Button title={shortenAddress(account)}></Button>
                    <Button onClick={handleLogout} title='Logout' addclass='style3 no-margin'>Logout</Button>
                    </>
                    ) : (
                    <Button onClick={connectToWeb3} title='Connect' addclass='style1'/>
                )}
                <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
            </div>
        </div>
        </header>
            )}
        </div>
    );
}

export default Header;
