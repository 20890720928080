

const dataToken = [
    {
        id: 1,
        title: 'Community',
    }, 
    {
        id: 2,
        title: 'Seed Sale',
    }, 
    {
        id: 3,
        title: 'Liquidity',
    }, 
    {
        id: 4,
        title: 'Public Sale',
    }, 
    {
        id: 5,
        title: 'Team',
    }, 
    // {
    //     id: 6,
    //     title: 'Liquidity',
    // },
    // {
    //     id: 7,
    //     title: 'Marketing',
    // },
    // {
    //     id: 8,
    //     title: 'Team',
    // },

]

export default dataToken;