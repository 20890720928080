import React, {useEffect} from 'react';

import {
    DEFAULT_ADDRESS,
} from "../constants/constants";
import {useLocation, useParams, useNavigate} from "react-router-dom";


function Ref(props) {
    let {ref} = useParams()
    const navigate = useNavigate();

    useEffect(() => {
        saveRefAddress()
    }, [])

    const saveRefAddress = async () => {
        if (ref) {
            await localStorage.setItem('refAccount', ref);
        } else {
            await localStorage.setItem('refAccount', DEFAULT_ADDRESS);
        }
      await navigate('/affiliate', { replace: true });
    }

    return (
        <div className='inner-page'>
        </div>)
}

export default Ref;
