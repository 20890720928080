import img1 from '../images/common/icon_9.png';
import img2 from '../images/common/icon_10.png';
import img3 from '../images/common/icon_11.png';
// import img7 from "../images/common/project_5.png";
// import img8 from "../images/common/project_6.png";
// import img9 from "../images/common/project_7.png";

const dataStep = [
    // {
    //     id: 1,
    //     img: img1,
    //     title: 'Submit KYC',
    //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.'
    // },
    // {
    //     id: 2,
    //     img: img2,
    //     title: 'Verify Wallet',
    //     text: 'Etiam nisi libero, sodales sit amet justo ac, suscipit maximus metus. Semper nec interdum nec, faucibus id dui sit amet congue'
    // },
    // {
    //     id: 3,
    //     img: img3,
    //     title: 'Start Staking',
    //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.'
    // },

    {
        id: 1,
        img: img1,
        title: "Select and Secure",
        desc: "Choose a potential project that resonates with you and purchase an amount of LIF tokens. These tokens serve as your commitment to join the IDO and become a part of the project's growth journey.",
        number: "01"
    },
    {
        id: 2,
        img: img2,
        title: "Solidify Your Intent",
        desc: "Demonstrate your dedication by burning a portion of LIF tokens. This act not only confirms your participation but also contributes to the scarcity of LIF, enhancing its value.",
        number: "02"
    },
    {
        id: 3,
        img: img3,
        title: "Timing is Key",
        desc: "Patiently await the public opening time. When the moment arrives, commit the precise number of tokens specified for your IDO participation.",
        number: "03"
    },

]

export default dataStep;