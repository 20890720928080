import React from 'react';

import PageTitle from '../components/pagetitle';
import CTA from '../components/cta/cta_v2';

import icon from '../assets/images/common/down.svg'




function Roadmap(props) {
    return (
        <div className='inner-page'>
            {<PageTitle title='RoadMap' />}

            <section className="tf-section roadmap">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="roadmap-wrapper-style2" data-aos="fade-up" data-aos-duration="1200">
                        <div className="left">
                            <div className="roadmap-box-style ">
                                <div className="icon">
                                    <img src={icon} alt="" />
                                </div>
                                <div className="content">
                                    <h6 className="date">Phase 1</h6>
                                    <ul>
                                        <li>Define project goals, objectives, and scope.</li>
                                        <li>Create a project team and assign roles and responsibilities.</li>
                                        <li>Conduct initial market research to validate the project idea.</li>
                                        <li>Identify key features and functionalities.</li>
                                        <li>Begin drafting initial design sketches or prototypes.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="roadmap-box-style ">
                                <div className="icon">
                                    <img src={icon} alt="" />
                                </div>
                                <div className="content">
                                    <h6 className="date">Phase 2</h6>
                                    <ul>
                                        <li>Refine the project concept and design based on feedback.</li>
                                        <li>Create detailed technical specifications.</li>
                                        <li>Develop mockups for user interfaces.</li>
                                        <li>Start building a functional prototype of the project.</li>
                                        <li>Develop a marketing and promotional plan.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="roadmap-box-style">
                                <div className="icon">
                                    <img src={icon} alt="" />
                                </div>
                                <div className="content">
                                    <h6 className="date">Phase 3</h6>
                                    <ul>
                                        <li>Conduct thorough testing across different scenarios.</li>
                                        <li>Perform bug fixing and error resolution.</li>
                                        <li>Ensure the project meets quality standards.</li>
                                        <li>Prepare documentation, user guides, and support materials.</li>
                                        <li>Create a marketing strategy that outlines target audiences, messaging, and promotional channels.</li>
                                        <li>Launch social media profiles and a dedicated website to establish an online presence.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="roadmap-box-style">
                                <div className="icon">
                                    <img src={icon} alt="" />
                                </div>
                                <div className="content">
                                    <h6 className="date">Phase 4</h6>
                                    <ul>
                                        <li>Finalize the details of the seed sale, including token price, allocation, and vesting schedules.</li>
                                        <li>Ensure compliance with legal requirements and regulations for the sale.</li>
                                        <li>Secure listings on prominent cryptocurrency exchanges for liquidity and accessibility.</li>
                                        <li>Coordinate exchange listings to coincide with the public sale phase.</li>
                                        <li>Set up customer support channels.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="roadmap-box-style ">
                                <div className="icon">
                                    <img src={icon} alt="" />
                                </div>
                                <div className="content">
                                    <h6 className="date">Phase 5</h6>
                                    <ul>
                                        <li>Establish a dedicated section on the launchpad's platform for project submissions.</li>
                                        <li>Develop guidelines and criteria for evaluating and selecting new projects.</li>
                                        <li>Reach out to promising projects in the blockchain space and encourage them to submit their proposals.</li>
                                        <li>Facilitate the onboarding process for selected projects, assisting them through the launchpad's token sale stages.</li>
                                        <li>Encourage the existing launchpad community to participate in the selection process by voting or providing feedback on potential projects.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="roadmap-box-style ">
                                <div className="icon">
                                    <img src={icon} alt="" />
                                </div>
                                <div className="content">
                                    <h6 className="date">Phase 6</h6>
                                    <ul>
                                        <li>Coordinate the launch and promotion of new projects on the launchpad.</li>
                                        <li>Provide support to the newly onboarded projects to ensure their successful token sale events.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="roadmap-box-style">
                                <div className="icon">
                                    <img src={icon} alt="" />
                                </div>
                                <div className="content">
                                    <h6 className="date">Phase 7</h6>
                                    <ul>
                                        <li>Explore partnerships and collaborations to enhance the project.</li>
                                        <li>Continuously analyze market trends and user preferences for long-term success.</li>
                                        <li>Implement solutions to enhance the platform's scalability, ensuring it can handle increased user and project activity.</li>
                                        <li>Consider integrating layer 2 solutions or other scaling technologies if applicable.</li>
                                        <li>Explore options to make the launchpad compatible with multiple blockchain networks, enabling projects to launch on different chains.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
            {<CTA />}

        </div>
    );
}

export default Roadmap;
