import React, {useEffect, useState} from 'react';
import { ReactTree } from '@naisutech/react-tree'

import PageTitle from '../components/pagetitle';
import CTA from '../components/cta/cta_v2';
import Web3Service from "../services/web3Service";
import {FaUserPlus, FaDollarSign, FaCopy} from  'react-icons/fa';
import ReactLoading from 'react-loading';
import { Tooltip } from 'react-tooltip'

import {
    DEFAULT_ADDRESS,
    LIF_ABI,
    LIF_CONTRACT,
    SALE_ABI,
    SALE_CONTRACT,
    USDT_ABI,
    USDT_CONTRACT
} from "../constants/constants";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import {shortenAddress} from "../utils/untils";

function Affiliate(props) {
    const [web3, setWeb3] = useState(null);
    const [loading, setLoading] = useState(false);
    const [account, setAccount] = useState(null);
    const [tree, setTree] = useState([])
    const [logicContract, setLogicContract] = useState(null)
    const [usdtContract, setUsdtContract] = useState(null)
    const [tokenContract, setTokenContract] = useState(null)
    const [userActive, setUserActive] = useState(null)
    const [activeTime, setActiveTime] = useState(null)
    const [userBonus, setUserBonus] = useState("0")
    const [canClaim, setCanClaim] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    // const [params, setParams] = useParams();
    const [copyValue, setCopyValue] = useState('');
    const { pathname } = useLocation();
    const [isCopied, setIsCopied] = useState(false);
    let {ref} = useParams()
    const location = useLocation()

    useEffect(() => {
        const initializeWeb3 = async () => {
            setLoading(true)
            if (!localStorage.getItem('ethereumAccount')) {
                setLoading(false)
                setAccount(null)
                return;
            }

            await Web3Service.initWeb3();
            const web3Instance = Web3Service.getWeb3();
            const userAccount = Web3Service.getAccount();
            // Check for null values or handle potential errors
            if (!web3Instance || !userAccount) {
                console.error('Web3 or account not available.');
                localStorage.removeItem('ethereumAccount')
                setLoading(false)
                return;
            }
            setCopyValue(window.location.origin + '/ref'+ '/' + userAccount)
            // Update component state with web3 data
            await setWeb3(web3Instance);
            await setAccount(userAccount);
            await setLogicContract(new web3Instance.eth.Contract(SALE_ABI, SALE_CONTRACT))
            await setUsdtContract(new web3Instance.eth.Contract(USDT_ABI, USDT_CONTRACT))
            await setTokenContract(new web3Instance.eth.Contract(LIF_ABI, LIF_CONTRACT))
            setLoading(false)
        };

        // Fetch Web3 data from the already initialized Web3Service
        initializeWeb3()
    }, [localStorage.getItem('ethereumAccount')]);

    useEffect(() => {
        if (logicContract && account && web3) {
            checkUserActive()
            checkHasBonus()
            fetchLowerLevelReferrals(account)
            checkCanClaim()
            checkUserActiveTime()
            handleRef()
        }
    }, [logicContract, account, web3])

    const handleRef = async () => {
        if (account) {
            let refer = localStorage.getItem('refAccount')
            if (refer && web3.utils.isAddress(refer) && refer != account) {
                localStorage.setItem('refAccount', refer);
            } else {
                localStorage.setItem('refAccount', DEFAULT_ADDRESS);
            }
        }
    }

    const fetchLowerLevelReferrals = async (currentAddress) => {
        // const lowerLevel1 = await logicContract.methods.getLowerLevel1Referrers(currentAddress).call();
        const tx = {
            to: SALE_CONTRACT,
            data: await logicContract.methods.getLowerLevel1Referrers(currentAddress).encodeABI(),
        };
        let lowerLevel1 = web3.eth.abi.decodeParameter('address[]', await web3.eth.call(tx))
        // Initialize an array to store the hierarchical data
        let hierarchicalData = [];
        const parent = {
            id: currentAddress,
            parentId: null,
            label: shortenAddress(currentAddress),
        };

        hierarchicalData.push(parent);
        // Recursive function to structure the data
        const getRecursiveHierarchicalData = async (referrerAddress, parentId) => {
            const txRecursive = {
                to: SALE_CONTRACT,
                data: await logicContract.methods.getLowerLevel1Referrers(referrerAddress).encodeABI(),
            };
            const lowerLevel = web3.eth.abi.decodeParameter('address[]', await web3.eth.call(txRecursive));
            if (lowerLevel.length > 0) {
                for (const address of lowerLevel) {
                    const node = {
                        id: address,
                        parentId: parentId,
                        label: shortenAddress(address),
                    };
                    if(address!==DEFAULT_ADDRESS) {
                        hierarchicalData.push(node);
                        await getRecursiveHierarchicalData(address, address);
                    }
                }
            }
        };

        // Fetch and structure data for each lower-level 1 referral
        for (const address of lowerLevel1) {
            const node = {
                id: address,
                parentId: currentAddress,
                label: shortenAddress(address),
            };
            if(address!==DEFAULT_ADDRESS) {
                hierarchicalData.push(node);
                await getRecursiveHierarchicalData(address, address);
            }
        }

        setTree(hierarchicalData);
    };

    const checkUserActive = async () => {
        try {
            const tx = {
                to: SALE_CONTRACT,
                data: await logicContract.methods.hasBoughtTokens(account).encodeABI(),
            };
            // let checkHasActive = await logicContract.methods.hasBoughtTokens(account).call();
            let checkHasActive = web3.eth.abi.decodeParameter('bool', await web3.eth.call(tx))
            setUserActive(checkHasActive)
        } catch (error) {
            alert(error)
        }
    }

    const checkUserActiveTime = async () => {
        try {
            const tx = {
                to: SALE_CONTRACT,
                data: await logicContract.methods.getUserPurchaseTimestamp(account).encodeABI(),
            };
            // let checkHasActive = await logicContract.methods.hasBoughtTokens(account).call();
            let activeTimest = web3.eth.abi.decodeParameter('uint256', await web3.eth.call(tx))
            setActiveTime(Number(activeTimest)*1000)
        } catch (error) {
            alert(error)
        }
    }

    const checkCanClaim = async () => {
        const tx = {
            to: SALE_CONTRACT,
            data: await await logicContract.methods.canClaimTokens(account).encodeABI(),
        };
        let checkCanClaim = web3.eth.abi.decodeParameter('uint256', await web3.eth.call(tx))
        setCanClaim(checkCanClaim)
    }
    const checkHasBonus = async () => {
        // let bonus = await logicContract.methods.getUserBonusBalance(account).call();
        const tx = {
            to: SALE_CONTRACT,
            data: await logicContract.methods.getUserBonusBalance(account).encodeABI(),
        };
        let bonus = web3.eth.abi.decodeParameter('uint256', await web3.eth.call(tx))
        setUserBonus(web3.utils.fromWei(bonus, 'ether'))
    }
    const handleCopyClick = () => {
        navigator.clipboard.writeText(copyValue)
            .then(() => {
                setIsCopied(true);
            })
            .catch((error) => {
                if (copyValue) {
                    // Create a temporary input element to copy the text
                    const tempInput = document.createElement('input');
                    tempInput.value = copyValue;
                    document.body.appendChild(tempInput);
                    tempInput.select();
                    document.execCommand('copy');
                    document.body.removeChild(tempInput);
                    setIsCopied(true);
                    return;
                } else {
                    setIsCopied(false);
                }
                setIsCopied(false);
            });
    };

    const registerUser = async () => {
        try {
            setLoading(true)
            const txAllow = {
                to: USDT_CONTRACT,
                data: await usdtContract.methods.allowance(account, SALE_CONTRACT).encodeABI(),
            };
            let allowance = web3.eth.abi.decodeParameter('uint256', await web3.eth.call(txAllow));
            let approveNumber = await web3.utils.toWei(BigInt(15).toString(),'ether')
            if (parseInt(allowance) <=0) {
                    /* global BigInt */
                    let approveNumberTest = web3.utils.toWei(BigInt(15000).toString(), 'ether');

                    let txData = {
                        from: account,
                        to: USDT_CONTRACT,
                        data: await usdtContract.methods.approve(SALE_CONTRACT, approveNumberTest).encodeABI(),
                    }
                    const tx = {
                        from: account,
                        to: USDT_CONTRACT,
                        value: '0x0',
                        data: await usdtContract.methods.approve(SALE_CONTRACT, approveNumberTest).encodeABI(),
                        gasLimit: await web3.eth.estimateGas(txData)
                    };
                    await web3.eth.sendTransaction(tx);
                }
                const gasActive = {
                    from: account,
                    to: SALE_CONTRACT,
                    data: await logicContract.methods.registerUser(localStorage.getItem('refAccount'), approveNumber).encodeABI(),
                }

                const activeTx = {
                    from: account,
                    to: SALE_CONTRACT,
                    value: '0x0',
                    data: await logicContract.methods.registerUser(localStorage.getItem('refAccount'), approveNumber).encodeABI(),
                    gasLimit: await web3.eth.estimateGas(gasActive)
                }

                await web3.eth.sendTransaction(activeTx);
                setUserActive(true)

            setLoading(false)
        } catch (error) {
            alert(error)
            setLoading(false)
        }
    }

    const claimToken = async () => {
        try {
            setLoading(true)
            const gasActive = {
                from: account,
                to: SALE_CONTRACT,
                data: await logicContract.methods.claimTokens().encodeABI(),
            }

            const mintTx = {
                from: account,
                to: SALE_CONTRACT,
                value: '0x0',
                data: await logicContract.methods.claimTokens().encodeABI(),
                gasLimit: await web3.eth.estimateGas(gasActive)
            }
            await web3.eth.sendTransaction(mintTx);
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const claimBonus = async () => {
        try {
            setLoading(true)
            const gasActive = {
                from: account,
                to: SALE_CONTRACT,
                data: await logicContract.methods.claimBonuses().encodeABI(),
            }

            const bonusTx = {
                from: account,
                to: SALE_CONTRACT,
                value: '0x0',
                data: await logicContract.methods.claimBonuses().encodeABI(),
                gasLimit: await web3.eth.estimateGas(gasActive)
            }
            await web3.eth.sendTransaction(bonusTx);
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }
    return (
    <div className='inner-page'>
        {loading? (
            <ReactLoading type={'spin'} className={'spin-overlay'} width={'100%'} height={'100%'}></ReactLoading>
        ): (<span></span>)}

        {<PageTitle title='Affiliate program' />}
        { account ? (
            <div>
            <section className="tf-section tf-contact pt20">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="contact-info">
                                    <li>
                                        <div className="icon">
                                            <FaUserPlus size={'3rem'}></FaUserPlus>
                                        </div>
                                        <div className="content">
                                            <p>Total activated user</p>
                                            <p className="white">{tree.length - 1}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <FaDollarSign size={'3rem'}></FaDollarSign>
                                        </div>
                                        <div className="content">
                                            <p>Total bonus</p>
                                            <p className="white">{userBonus}</p>
                                        </div>
                                    </li>
                                    {/*<li>*/}
                                    {/*    <div className="icon">*/}
                                    {/*        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*            <path d="M27.1875 8.4375V21.5625C27.1875 22.3084 26.8912 23.0238 26.3637 23.5512C25.8363 24.0787 25.1209 24.375 24.375 24.375H5.625C4.87908 24.375 4.16371 24.0787 3.63626 23.5512C3.10882 23.0238 2.8125 22.3084 2.8125 21.5625V8.4375M27.1875 8.4375C27.1875 7.69158 26.8912 6.97621 26.3637 6.44876C25.8363 5.92132 25.1209 5.625 24.375 5.625H5.625C4.87908 5.625 4.16371 5.92132 3.63626 6.44876C3.10882 6.97621 2.8125 7.69158 2.8125 8.4375M27.1875 8.4375V8.74125C27.1876 9.22143 27.0647 9.69363 26.8305 10.1129C26.5964 10.5321 26.2588 10.8844 25.85 11.1363L16.475 16.905C16.0315 17.1782 15.5209 17.3228 15 17.3228C14.4791 17.3228 13.9685 17.1782 13.525 16.905L4.15 11.1375C3.74115 10.8857 3.40359 10.5334 3.16946 10.1141C2.93534 9.69488 2.81245 9.22268 2.8125 8.7425V8.4375" stroke="#798DA3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>*/}
                                    {/*            </svg>*/}

                                    {/*    </div>*/}
                                    {/*<div className="content">*/}
                                    {/*    <p></p>*/}
                                    {/*    <p className="color-hover">10</p>*/}
                                    {/*</div>*/}
                                    {/*</li>*/}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            <section className="tf-section tf-contact pt20">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="project-info-form">
                                <h6 className="title">Join with us</h6>
                                <div className="wrap-btn">
                                    {!userActive ? (<span></span>) : (<span></span>) }
                                    {userBonus > 0 ? (
                                        <button className="tf-button style2" onClick={claimBonus}>
                                            Claim bonus
                                        </button>
                                    ): (<span></span>)}

                                            <button className="tf-button style1" onClick={claimToken}>
                                                Mint token
                                            </button>
                                    {
                                     activeTime ? (
                                         <p>You can mint your token everyday from {new Date (new Date(activeTime).setDate(new Date(activeTime).getDate()+1)).toLocaleDateString() + ' ' +new Date(activeTime).toLocaleTimeString()}</p>
                                     ): (<span></span>)
                                    }

                                </div>
                                <div className='pt10'>
                                    <h6>Ref url</h6>
                                    <fieldset>
                                        <input type="text" className="ref-url" disabled={true} value={copyValue}/>
                                        <FaCopy size={'2rem'} className='copy-button' data-tooltip-id='copy-tooltip' data-tooltip-content={isCopied ? "Copied!" : ""} onClick={handleCopyClick}></FaCopy>
                                        <Tooltip id="copy-tooltip"></Tooltip>
                                    </fieldset>
                                </div>
                                <div className='pt10'>
                                    <h6 className="title">Total members</h6>
                                    <ReactTree nodes={tree} theme={'dark'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
        ) : (<span></span>)
        }


            {<CTA />}
        </div>
    )
}

export default Affiliate;
